import React, { useState, useEffect } from "react";
import { bool, string } from "prop-types";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import LeadForm from "../../../components/Forms/LeadForm";
import Image from "../../../components/image";
import TrustedNumnbers from "../../../components/TrustedNumbers/TrustedNumbers";
import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { demoTestimonials } from "../../../data/restaurant-data";
import { terminalTrustedNumbers } from "../../../data/terminal-landing-data";
import data, { dataWithLineOfBusiness } from "../../../data/forms/lead-form";
import { formItemVisibilityEventName, urlIndustries } from "../../../constants";
import { mapUrlIndustryToFormValue } from "../../../utils/url-utils";
import { extractDefaultValues } from "../../../utils/form-utils";

// import '../styles/demo.less';
import ogImage from "../../../images/global_assets/og-image.png";

export default function Demo({
  useFormValueAsDataLayerEventName,
  formValueForDataLayerEventName,
  dataLayerEventNameAppendix,
}) {
  const [displayForm, setDisplayForm] = useState(false);
  const [formData, setFormData] = useState(dataWithLineOfBusiness || []);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { pathname } = window.location;
      const industries = pathname.split("/").filter((i) => i.length > 0);
      const industry = industries.length ? industries[0] : "";

      const hasIndustryInTheUrl = urlIndustries.includes(industry);
      const newFormData = formData.map((item) => {
        if (item.name === "industry" && hasIndustryInTheUrl) {
          return { ...item, defaultValue: mapUrlIndustryToFormValue(industry) };
        }
        return item;
      });

      setInitialValues(extractDefaultValues(newFormData));
      setFormData(newFormData);
      setDisplayForm(true);
    }
  }, []);

  const onValuesChange = (item) => {
    if (item.industry) {
      if (typeof window !== "undefined") {
        const event = new CustomEvent(formItemVisibilityEventName, {
          detail: {
            key: "industry",
            value: item.industry,
          },
        });
        window.dispatchEvent(event);
      }
    }
  };

  return (
    <Layout>
      <SEO title="Learn more" image={`https://spoton.com/${ogImage}`} />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            <LeadForm
              formName="Lead Form"
              formId="29af0800-d585-4224-8b6c-50acaa7ed194"
              displayForm={displayForm}
              formData={formData}
              initialValues={initialValues}
              header="Get your free demo"
              subHeader="Learn how SpotOn products can help you run and grow your business."
              onValuesChange={onValuesChange}
              thankYouPath="lp/veterans/thank-you"
              toggleVisibilityMap={[
                {
                  changeOnKey: "industry",
                  changeOnValue: "Other",
                  togglesItem: "business_type",
                },
              ]}
              formItemsToSend={[
                "firstname",
                "email",
                "phone",
                "company",
                "industry",
                "business_type",
                "text_messages",
                "product",
              ]}
              useFormValueAsDataLayerEventName={
                useFormValueAsDataLayerEventName
              }
              formValueForDataLayerEventName={formValueForDataLayerEventName}
              dataLayerEventNameAppendix={dataLayerEventNameAppendix}
              withLineOfBusinessOption
            />
          </div>
        </div>
        <Image imageName="demo_image.png" className="demo__heading-img" />
        <section className="trusted-numbers-wrapper">
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <PartnerLogos />
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
    </Layout>
  );
}

Demo.propTypes = {
  useFormValueAsDataLayerEventName: bool,
  formValueForDataLayerEventName: string,
  dataLayerEventNameAppendix: string,
};

Demo.defaultProps = {
  useFormValueAsDataLayerEventName: true,
  formValueForDataLayerEventName: "industry",
  dataLayerEventNameAppendix: "-lead",
};
